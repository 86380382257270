var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"prsc",attrs:{"id":"proPage"}},[_c('div',{staticClass:"wrap"},[_c('div',{staticClass:"f-select s01",on:{"click":function($event){return _vm.showselect(1)}}},[_c('div',{staticClass:"s-t"},[_vm._v("模型名称")]),_c('div',{staticClass:"fs-text"},[_c('div',{staticClass:"text-c"},[_vm._v(" "+_vm._s(_vm.value01.text)+" ")]),_c('img',{staticClass:"icon-s",attrs:{"src":require("@/assets/img/i13.png"),"alt":""}})])]),_c('div',{staticClass:"obv-wrap"},[(_vm.imgdata&&_vm.imgdata.FileType == 2)?_c('img',{staticClass:"imgdata",attrs:{"src":_vm.baseUrl + _vm.imgdata.ImgUrl,"alt":""}}):_vm._e(),_c('obvCompIndex',{ref:"obvCompIndex",on:{"loaded":_vm.loaded}})],1),_c('div',{staticClass:"f-select s02",on:{"click":function($event){return _vm.showselect(2)}}},[_c('div',{staticClass:"s-t"},[_vm._v("选择模型列表")]),_c('div',{staticClass:"fs-text"},[_c('div',{staticClass:"text-c"},[_vm._v(" "+_vm._s(_vm.value02.text)+" ")]),_c('img',{staticClass:"icon-s",attrs:{"src":require("@/assets/img/i13.png"),"alt":""}})])]),_c('div',{staticClass:"f-select s03",on:{"click":function($event){return _vm.showselect(3)}}},[_c('div',{staticClass:"s-t"},[_vm._v("选择生产状态")]),_c('div',{staticClass:"fs-text"},[_c('div',{staticClass:"text-c",class:`status${_vm.value03.status}`},[_vm._v(" "+_vm._s(_vm.value03.text)+" ")]),_c('img',{staticClass:"icon-s",attrs:{"src":require("@/assets/img/i13.png"),"alt":""}})])]),(_vm.value02 && _vm.value02.ID)?_c('div',{staticClass:"des-c"},[_vm._m(0),_c('van-field',{attrs:{"type":"textarea","placeholder":"","autosize":_vm.autosize,"border":_vm.Btrue,"show-word-limit":"","maxlength":"100"},model:{value:(_vm.describe),callback:function ($$v) {_vm.describe=$$v},expression:"describe"}})],1):_vm._e(),_c('div',{staticClass:"btn-s b01",class:{
        disable:
          !(
            _vm.value02 &&
            _vm.value02.ID &&
            _vm.value03.status != -1 &&
            _vm.value01 &&
            _vm.value01.ID
          ) || _vm.loading,
      },on:{"click":_vm.next}},[_vm._v(" 确认 ")])]),(_vm.pshow && _vm.columns && _vm.columns.length)?_c('van-picker',{attrs:{"title":"标题","show-toolbar":"","columns":_vm.columns,"default-index":_vm.defaultindex},on:{"confirm":_vm.onConfirm,"cancel":_vm.onCancel,"change":_vm.onChange}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"f-select s04"},[_c('div',{staticClass:"s-t"},[_vm._v("进度说明")])])
}]

export { render, staticRenderFns }